/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-logoslider__imagelink {
  display: flex;
  justify-content: center;
}
.w-logoslider__imagelink[href] img {
  transition: all 0.3s;
}
@media (hover: hover) {
  .w-logoslider__imagelink[href]:hover img {
    filter: sepia(1);
  }
}

.w-logoslider__imagecontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
:root {
  --header-logo-height: 45px;
  --header-submenu-pos-top: calc(100% - 30px);
}

.header {
  position: fixed;
  width: 100%;
  height: var(--header-height);
  top: 0;
  z-index: 200;
  background-color: #fff;
}

.header--nopageheader {
  margin-bottom: var(--widget-margin-bottom);
}

.offcanvas,
.dropdown-menu {
  --bs-dropdown-border-width: 0;
  --bs-dropdown-link-active-bg: transparent;
}

.header__side {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 768px) {
  .header {
    top: 0;
    z-index: 200;
  }
}

.header__dealerportalicon {
  font-size: 32px;
}

.header .logocontainer {
  display: flex;
  justify-content: center;
}
.header .logocontainer img {
  height: var(--header-logo-height);
}

.header__logoimg {
  transform: translateY(-2px);
}

.header__container {
  height: 100%;
  display: grid;
  grid-template-areas: "logo menu side";
  grid-template-columns: auto 1fr;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}
.header__container .header__menu {
  grid-area: menu;
}
.header__container .header__logo {
  grid-area: logo;
}
.header__container .header__side {
  grid-area: side;
}
.header__search {
  display: flex;
}

.header__hamburger {
  display: flex;
  position: relative;
}

.header__search,
.header__hamburger {
  cursor: pointer;
  font-size: 20px;
}
@media (max-width: 768px) {
  .header__search,
  .header__hamburger {
    font-size: 28px;
  }
}

.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ww-menuitems__menulink,
a.header__contactlink,
.mobilemenu a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  line-height: 130%;
}

.ww-menuitems__menulink[data-depth="1"] {
  font-weight: bold;
  display: inline-block;
  font-size: 20px;
  margin-bottom: 0.3em;
}

.header .ww-dropdown-icon-container {
  display: inline-block;
  transform: translate(-1px, 7px);
}
.header .ww-dropdown-icon-container .material-symbols-outlined {
  line-height: 0;
}

.ww-menuitems {
  --bs-link-hover-color: var(--bs-primary);
}

header .ww-dropdown-icon-container[data-depth="1"] {
  display: none;
}

#header-desktop-menu > .ww-menuitems {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

@media (min-width: 900px) and (max-width: 999px) {
  #header-desktop-menu > .ww-menuitems {
    gap: 10px;
  }
  .ww-menuitems__menulink[data-depth="0"] {
    font-size: 15px;
  }
  .ww-dropdown-icon-container[data-depth="0"] {
    margin-left: 3px;
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  #header-desktop-menu > .ww-menuitems {
    gap: 16px;
  }
  .ww-menuitems__menulink[data-depth="0"] {
    font-size: 16px;
  }
}
#header-desktop-menu > .ww-menuitems > li {
  padding-right: 0;
}

.header__desktopmenu .ww-menuitems--0 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header__desktopmenu > ul > li {
  line-height: 1;
}

.header__submenu.header__submenu--0 .header__submenucontainer {
  background: #f0f0f0;
}

.header__submenu.header__submenu--0 > .ww-menuitems {
  padding-left: 0;
  padding-bottom: 30px !important;
  margin-left: auto;
  margin-right: auto;
}

.header__submenu--0 .container-lg {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.header__desktopmenu .header__submenu--0 {
  display: none;
  animation: fade-in 300ms forwards;
  position: absolute;
  z-index: 100;
  z-index: 400;
  left: 0;
  top: var(--header-submenu-pos-top);
  padding-top: 30px;
  width: 100vw;
  line-height: 130%;
}
.header__desktopmenu .header__submenu--0 > ul {
  background-color: #f0f0f0;
  width: 100%;
  padding: 30px 92px 0 92px;
  padding: 30px 0 0 0;
  padding-left: var(--hk-page-side-padding);
  align-items: flex-start;
  gap: 5px;
}
.header__desktopmenu .header__submenu--0 > ul .ww-dropdown-icon-container {
  display: none;
}
@media (min-width: 1250px) {
  .header__desktopmenu .header__submenu--0 > ul {
    padding-left: calc((100vw - 1200px) / 2);
  }
}

.header__submenu--1 > .ww-menuitems li {
  margin-bottom: 4px;
}

.header__desktopmenu .has-submenu:hover > .header__submenu {
  display: block;
}

.mobilemenu {
  display: block;
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  background-color: #f0f0f0;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  text-align: right;
  z-index: 2000;
  transition: all 300ms;
  width: max-content;
  max-width: 90vw;
  min-width: 279px;
}
.mobilemenu.active {
  opacity: 1;
  pointer-events: auto;
}
.mobilemenu .ww-dropdown-icon-container {
  display: none;
}
.mobilemenu > .mobilemenu__items > li:nth-of-type(1n + 2) {
  margin-top: 0.5rem;
}
.mobilemenu > .mobilemenu__items > li > a .ww-dropdown-icon-container {
  display: inline-block;
}
.mobilemenu > .mobilemenu__items > li > .mobilemenu__submenu {
  height: 0;
  overflow: hidden;
}

.mobilemenu__itemlink--1 {
  margin-right: 16px;
}

.mobilemenu__itemlink--2 {
  margin-right: 32px;
}

.header__menu {
  display: none;
}

.header__contactlink {
  display: none;
}

@media (min-width: 900px) {
  .header__container {
    grid-template-areas: "logo menu side";
    grid-template-columns: auto 1fr auto;
  }
  .header__desktopmenu {
    display: flex;
    justify-content: center;
  }
  .header__menu {
    display: block;
  }
  .header__hamburger {
    display: none;
  }
  .header__contactlink {
    display: block;
  }
  .mobilemenu {
    display: none;
  }
}
@media (min-width: 1450px) {
  .header__desktopmenu .ww-menuitems {
    gap: 20px;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
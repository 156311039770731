@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
:root {
  --ww-widget-textimage-border-radius: 0;
  --bs-primary: #065199;
  --bs-secondary: #343d44;
  --widget-margin-bottom: 2rem;
  --header-height: 5rem;
  --color-font-black: #393939;
}

.show-ww-cookiewall .ww-cookiewall__container, .show-ww-cookiewall .ww-cookiewall {
  display: block;
}

.hide-ww-cookiewall .ww-cookiewall__container, .hide-ww-cookiewall .ww-cookiewall {
  display: none;
}

.ww-cookiewall {
  position: relative;
  z-index: 20000;
  display: none;
  background: var(--ww-cookiewall-background-color);
}
.ww-cookiewall--acceptdecline {
  position: fixed;
}
.ww-cookiewall.hide {
  display: none;
}
.ww-cookiewall.show {
  display: block;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__container {
  padding: 32px 16px;
}
.ww-cookiewall__text > *:first-child {
  margin-top: 0;
}
.ww-cookiewall__text > *:last-child {
  margin-bottom: 0;
}
.ww-cookiewall__text,
.ww-cookiewall__text > * {
  color: var(--ww-cookiewall-text-color);
}
.ww-cookiewall__text a {
  color: var(--ww-cookiewall-link-text-color);
}

.ww-cookiewall--specific {
  background: var(--ww-cookiewall-backdrop-color);
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  display: none;
}

.ww-cookiewall--specific .ww-cookiewall__container {
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  position: relative;
  max-width: 95vw;
  min-width: 325px;
  width: max-content;
  background-color: var(--ww-cookiewall-background-color);
  color: var(--ww-cookiewall-text-color);
  border-radius: 5px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  padding: 1.5em;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall--specific .ww-cookiewall__container {
    max-width: 600px;
    padding: 3em 3.5em;
  }
}

.ww-cookiewall__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall__buttons {
    flex-direction: row;
    gap: 0;
  }
}

.ww-cookiewall .btn {
  width: 100%;
  padding: 0.5em 1rem;
  font-size: inherit;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall .btn {
    width: 60%;
  }
}

.ww-cookiewall__link--check {
  color: var(--ww-cookiewall-link-text-color);
  cursor: pointer;
}

.ww-cookiewall--specific #gdpr-cookie-message {
  transition: all 3.5s ease;
  -webkit-transition: all 3.3s ease; /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all 3.3s ease;
}

.ww-cookiewall--specific #gdpr-cookie-types {
  display: none;
  transition: all 3.5s ease;
  -webkit-transition: all 3.3s ease; /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all 3.3s ease;
  margin-top: 1.5rem;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall--specific #gdpr-cookie-types {
    margin: 0;
  }
}

.ww-cookiewall__message {
  max-width: 450px;
  margin: 0 auto;
}
.ww-cookiewall__message > * {
  font-size: 14px;
  margin-bottom: 1.5rem;
}
.ww-cookiewall__message > p:first-of-type {
  color: var(--ww-cookiewall-heading-color);
  font-size: 18px;
}
.ww-cookiewall__message > *:last-child {
  margin-bottom: 0;
}

.ww-cookiewall--specific .wh-form__fieldline {
  padding: 0.5em;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall--specific .wh-form__fieldline {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.ww-cookiewall--specific .wh-form__fieldline .switch {
  margin-right: 0.75em;
}

#gdpr-cookie-check, #gdpr-cookie-check-back, #gdpr-cookie-save {
  cursor: pointer;
}

#gdpr-cookie-check, #gdpr-cookie-save {
  text-decoration: underline;
}

#gdpr-cookie-check-back {
  font-size: 1.25rem;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  #gdpr-cookie-check-back {
    margin-bottom: 0.7em;
  }
}

.ww-cookiewall__cookie-items {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding-left: 0;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall__cookie-items {
    width: 50%;
  }
}

.ww-cookiewall__cookie-item {
  width: 100%;
  display: inline-block;
  cursor: pointer;
}

.ww-cookiewall__cookie-item-all {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ww-cookiewall__cols {
    display: flex;
  }
  .ww-cookiewall__col--buttons {
    padding-left: 50px;
  }
  .ww-cookiewall--acceptdecline .ww-cookiewall__cols {
    align-items: center;
  }
  .ww-cookiewall--acceptdecline .ww-cookiewall__col--text {
    margin-bottom: 0;
  }
}
.ww-cookiewall--acceptdecline {
  bottom: 0;
  left: 0;
  right: 0;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__container {
  padding: 2rem 0;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__col--text {
  display: flex;
  align-items: center;
  flex: 1;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__col--buttons {
  display: flex;
  align-items: center;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__button {
  margin-right: 15px;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__button:last-child {
  margin-right: 0;
}

.wh-requireconsent {
  position: relative;
  z-index: 0;
}
.wh-requireconsent__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.wh-requireconsent__overlay a {
  color: inherit;
}
.wh-requireconsent__overlay[hidden] {
  display: none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Styling for disabled item */
.switch input[disabled] + .slider {
  opacity: 0.5;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--ww-cookiewall-switch-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
/* Typography

   Font: Roboto

   H1: 48px
   H2: 36px
   H3: 24px
   H4: 22px
   Body: 16px/24px, 400

   Heading weight: 500
   Heading color: #1d1d1b or #fff
   Heading line-height: 125%
*/
:root {
  --bs-font-sans-serif: "Roboto", sans-serif;
  --bs-body-font-size: 16px;
  --bs-body-line-height: 150%;
  --bs-body-color: #000000;
  --bs-link-color: var(--bs-secondary);
  --bs-link-hover-color: var(--bs-primary);
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.ww-p-overview--withfilters .c-filters__filtertitle {
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 0.4em;
  color: #1d1d1b;
}

h1,
.h1 {
  font-size: 48px;
}

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4,
.ww-p-overview--withfilters .c-filters__filtertitle {
  font-size: 22px;
}

body,
p,
ul,
ol {
  font-size: 16px;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.a--no-underline {
  text-decoration: none;
}

p {
  margin-bottom: 1em;
}

.text-right {
  text-align: right;
}

.date-label {
  background: var(--bs-secondary);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: #fff;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
#app {
  z-index: 150;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 2rem;
}

.offcanvas {
  --bs-dropdown-font-size: 20px;
}

.btn {
  --bs-btn-border-radius: 10rem;
  --bs-btn-padding-x: 2rem;
  --bs-btn-padding-y: 0.6rem;
  --bs-btn-line-height: 1.2;
  --bs-border-color: var(--bs-primary);
}
@media (hover: hover) {
  .btn:hover {
    --bs-btn-hover-bg: var(--bs-secondary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-border-color: var(--bs-secondary);
  }
}

.breadcrumb {
  --bs-breadcrumb-margin-bottom: 0;
}

/* Imports */
.wh-form__imgedit {
  width: 200px;
  height: 200px;
}
.wh-form__imgeditdelete {
  cursor: pointer;
}
.wh-form__imgeditholder::after {
  z-index: 1;
  position: absolute;
  display: none;
  width: 60px;
  top: 50%;
  left: 50%;
  height: 60px;
  font-size: 60px;
  line-height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  opacity: 0.8;
}
.wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
}
.wh-form--uploading .wh-form__imgedit::after,
.wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}

.wh-form__textinput, .wh-form__textinput[type=password], .wh-form__uploadfieldfilename, .wh-form__textarea {
  width: 100%;
  font-size: var(--form-textinput-font-size);
  line-height: 100%;
  color: #000;
  font-weight: normal;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  padding: 10px;
  margin: 0;
  border: 1px solid #000;
  font-family: inherit;
}
.wh-form__textinput[disabled], .wh-form__textinput[type=password][disabled], .wh-form__uploadfieldfilename[disabled], .wh-form__textarea[disabled] {
  background: var(--ww-form-textinput-disabled-bg-color);
}
.wh-form__textarea {
  min-height: 120px;
}
.wh-form__dateinputgroup {
  max-width: 200px;
}
.wh-form__timeinputgroup {
  max-width: 140px;
}
.wh-form__textinput[type=number] {
  max-width: 200px;
}

.wh-form__uploadfield {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.wh-form__uploadfieldselect, .wh-form__uploadfieldfilename {
  cursor: pointer;
}
.wh-form__uploadfieldselect {
  white-space: nowrap;
  padding: 8px 24px;
  min-height: initial;
}
.wh-form__uploadfieldinputholder {
  flex-grow: 1;
  min-width: 300px;
  max-width: 100%;
}
.wh-form__uploadfieldfilename {
  width: 100%;
}
.wh-form__uploadfielddelete {
  top: 50%;
  transform: translate(-2px, -50%);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  width: 20px;
  line-height: 100%;
  position: absolute;
  right: 0;
}

/* Styling */
.wh-form__fieldgroup--required label::after {
  margin-left: 5px;
  content: "*";
  color: var(--form-color-error-required-indicator);
}
.wh-form__fields {
  width: 100%;
}
.wh-form__navbuttons {
  display: flex;
  justify-content: space-between;
}
.wh-form__button--next {
  margin-left: auto;
}
.wh-form__label {
  cursor: pointer;
}
.wh-form__fieldgroup {
  margin: 0 0 16px;
}
.wh-form__checkbox, .wh-form__radio {
  transform: scale(1.3);
  margin-right: 11px;
}
.wh-form__fieldgroup--checkbox.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--checkbox .wh-form__optionlabel::after, .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--checkboxgroup .wh-form__optionlabel::after, .wh-form__fieldgroup--radiogroup.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--radiogroup .wh-form__optionlabel::after {
  display: none;
}
.wh-form__fieldgroup--checkbox .wh-form__textinput, .wh-form__fieldgroup--checkboxgroup .wh-form__textinput, .wh-form__fieldgroup--radiogroup .wh-form__textinput {
  margin-left: 15px;
}
.wh-form__optionlabel {
  cursor: pointer;
}
.wh-form__fieldgroup--error .wh-form__textinput,
.wh-form__fieldgroup--error .wh-form__uploadfieldfilename,
.wh-form__fieldgroup--error .wh-form__textarea,
.wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: var(--ww-form-color-error-border);
}
.wh-form__error {
  color: var(--ww-form-color-error-message);
}

:root {
  --ww-forms-custom-checkbox-gap: 0.5em;
  --ww-forms-custom-checkbox-margin-bottom: 0;
  --ww-forms-custom-checkbox-size: 1.15em;
  --ww-forms-custom-checkbox-transform-y: -0.075em;
  --ww-forms-custom-checkbox-border-width: 1px;
  --ww-forms-custom-checkbox-border-radius: 0.15em;
  --ww-forms-custom-checkbox-color: #000;
  --ww-forms-custom-checkbox-width: 0.65em;
  --ww-forms-custom-checkbox-height: 0.65em;
}

:root {
  --ww-forms-custom-radio-gap: 0.5em;
  --ww-forms-custom-radio-margin-bottom: 0;
  --ww-forms-custom-radio-color: #000;
  --ww-forms-custom-radio-bgcolor: transparent;
  --ww-forms-custom-radio-border-width: 0.15em;
  --ww-forms-custom-radio-checked-scale: 1;
}

:root {
  --ww-forms-custom-select-background-color: #fff;
  --ww-forms-custom-select-font-size: 16px;
  --ww-forms-custom-select-height: 30px;
  --ww-forms-custom-select-max-width: 30ch;
  --ww-forms-custom-select-padding-y: 0.25em;
  --ww-forms-custom-select-padding-x: 0.5em;
  --ww-forms-custom-select-border-color: #777;
  --ww-forms-custom-select-arrow-color: var(--ww-forms-custom-select-border-color);
}

:root {
  --ww-forms-custom-checkbox-gap: 8px;
  --ww-forms-custom-checkbox-size: 16px;
  --ww-forms-custom-checkbox-margin-bottom: 4px;
  --ww-forms-custom-checkbox-transform-y: 4px;
  --ww-forms-custom-checkbox-color: var(--bs-primary);
  --ww-forms-custom-checkbox-width: 12px;
  --ww-forms-custom-checkbox-height: 12px;
  --ww-forms-custom-radio-color: var(--bs-primary);
  --ww-forms-custom-select-padding-y: 10px;
  --ww-forms-custom-select-padding-x: 10px;
}

.wh-form__fieldgroup--checkbox .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  display: grid;
  grid-template-columns: var(--ww-forms-custom-checkbox-size) auto;
  gap: var(--ww-forms-custom-checkbox-gap);
  margin-bottom: var(--ww-forms-custom-checkbox-margin-bottom);
}
.wh-form__fieldgroup--checkbox .wh-form__fieldline > label,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline > label {
  display: none;
}
.wh-form__fieldgroup--checkbox .wh-form__fieldline input[type=checkbox],
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: var(--ww-forms-custom-checkbox-size);
  height: var(--ww-forms-custom-checkbox-size);
  border: var(--ww-forms-custom-checkbox-border-width) solid currentColor;
  border-radius: var(--ww-forms-custom-checkbox-border-radius);
  transform: translateY(var(--ww-forms-custom-checkbox-transform-y));
  display: grid;
  place-content: center;
  background-color: var(--Ocre-200);
  cursor: pointer;
}
.wh-form__fieldgroup--checkbox .wh-form__fieldline input[type=checkbox][disabled],
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline input[type=checkbox][disabled] {
  cursor: unset;
}
.wh-form__fieldgroup--checkbox .wh-form__fieldline input[type=checkbox]::before,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline input[type=checkbox]::before {
  content: "";
  width: var(--ww-forms-custom-checkbox-width);
  height: var(--ww-forms-custom-checkbox-height);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--ww-forms-custom-checkbox-color);
  background-color: CanvasText;
}
.wh-form__fieldgroup--checkbox .wh-form__fieldline input[type=checkbox]:checked::before,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline input[type=checkbox]:checked::before {
  transform: scale(1);
}
.wh-form__fieldgroup--checkbox .wh-form__fieldline input[type=checkbox]::before,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline input[type=checkbox]::before {
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.wh-form__fieldgroup--checkbox .wh-form__optionlabel,
.wh-form__fieldgroup--checkboxgroup .wh-form__optionlabel {
  cursor: pointer;
}

.wh-form__fieldgroup--checkbox .wh-form__fieldline {
  align-items: center;
}
.wh-form__fieldgroup--checkbox .wh-form__fieldline input[type=checkbox] {
  transform: none;
}

.wh-form__fieldline:has(.wh-form__checkbox[disabled]) .wh-form__optionlabel {
  cursor: unset;
}

.wh-form__fieldgroup--radiogroup .wh-form__fieldline {
  display: grid;
  grid-template-columns: 1em auto;
  align-items: center;
  gap: var(--ww-forms-custom-radio-gap);
  margin-bottom: var(--ww-forms-custom-radio-margin-bottom);
}
.wh-form__fieldgroup--radiogroup .wh-form__fieldline input[type=radio] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: var(--ww-forms-custom-radio-border-width) solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  background-color: var(--ww-forms-custom-radio-bgcolor);
  display: grid;
  place-content: center;
}
.wh-form__fieldgroup--radiogroup .wh-form__fieldline input[type=radio]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--ww-forms-custom-radio-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}
.wh-form__fieldgroup--radiogroup .wh-form__fieldline input[type=radio]:checked::before {
  transform: scale(var(--ww-forms-custom-radio-checked-scale));
}
.wh-form__fieldgroup--radiogroup .wh-form__fieldline label[aria-hidden=true] {
  display: none;
}
.wh-form__fieldgroup--radiogroup .wh-form__optionlabel {
  cursor: pointer;
}

.wh-form__optiondata > .wh-form__subfield {
  margin-left: 0;
}

/*
  .wh-form__fieldgroup--pulldown .wh-form__fieldline,
  .wh-form__fieldline--subfields .wh-form__optiondata > .wh-form__subfield
  */
.wh-form__fieldgroup--pulldown .wh-form__fieldline {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  min-width: 15ch;
  max-width: var(--ww-forms-custom-select-max-width);
  border: 1px solid var(--ww-forms-custom-select-border-color);
  border-radius: 0.25em;
  font-size: var(--ww-forms-custom-select-font-size);
  line-height: 1.1;
  background-color: var(--ww-forms-custom-select-background-color);
}
.wh-form__fieldgroup--pulldown .wh-form__fieldline select, .wh-form__fieldgroup--pulldown .wh-form__fieldline::after {
  grid-area: select;
}
.wh-form__fieldgroup--pulldown .wh-form__fieldline::after {
  content: "";
  justify-self: end;
  width: 0.8em;
  height: 0.5em;
  background-color: var(--ww-forms-custom-select-arrow-color);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  pointer-events: none;
  margin-right: 0.5em;
}
.wh-form__fieldgroup--pulldown .wh-form__fieldline select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: var(--ww-forms-custom-select-padding-y) var(--ww-forms-custom-select-padding-x);
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
  cursor: pointer;
}

.pageheader {
  height: 600px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  z-index: 0;
  width: 100%;
}
.pageheader--video {
  aspect-ratio: 700/310;
  min-height: 300px;
  max-height: 60vh;
}
.pageheader--image {
  height: 300px;
  max-height: 50vh;
}
.pageheader--text {
  height: 200px;
  max-height: 40vh;
}
.pageheader:before {
  z-index: 5;
}
.pageheader .pageheader__image,
.pageheader .pageheader__videocontainer {
  z-index: 10;
}
.pageheader:after {
  z-index: 15;
}
.pageheader .pageheader__content {
  z-index: 20;
}
.pageheader:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #858a96;
  pointer-events: none;
}
.pageheader:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.pageheader .pageheader__content {
  position: relative;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
}
.pageheader .pageheader__content h1 {
  color: var(--bs-white);
}
.pageheader .pageheader__content a {
  margin-right: 1rem;
}
.pageheader .pageheader__videocontainer {
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
}
.pageheader .pageheader__videoposter,
.pageheader .pageheader__videoiframe {
  position: absolute;
  top: 50%;
  left: 50%;
  aspect-ratio: 16/9;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 960px) {
  .pageheader .pageheader__videoposter,
  .pageheader .pageheader__videoiframe {
    width: 100%;
  }
}
.pageheader .pageheader__videoposter {
  z-index: 5;
  display: block;
  width: 100%;
  z-index: 5;
}
.pageheader .pageheader__videoiframe {
  z-index: 10;
}
.pageheader .pageheader__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.pageheader__buttons {
  display: none;
}

@media (min-width: 768px) {
  .pageheader__buttons {
    display: flex;
    align-items: center;
  }
}
.breadcrumb__container:first-child {
  margin-top: var(--widget-margin-bottom);
}

.btn-primary {
  --bs-btn-disabled-bg: gray;
  --bs-btn-disabled-border-color: inherit;
  --bs-btn-disabled-opacity: 0.3;
}

.btn {
  background: var(--bs-primary);
  color: #fff;
  border-color: var(--bs-primary);
}
.btn--gray {
  background: var(--bs-secondary);
}
@media (hover: hover) {
  .btn--gray:hover {
    background: var(--bs-primary);
  }
}
.btn--white {
  background: #fff;
  border-color: #fff;
  color: var(--bs-secondary);
}
@media (hover: hover) {
  .btn--white:hover {
    background: var(--bs-primary);
  }
}
.btn--transparent {
  background: none;
  border-color: #000;
  color: #000;
}
.btn--read-more {
  background: none;
  border: none;
  color: var(--bs-secondary);
  padding: 4px 6px 4px 0;
}
.btn--read-more .icon-container {
  background: var(--bs-primary);
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 100%;
  margin-right: 12px;
  line-height: 1.5rem;
  display: inline-block;
}
.btn--read-more .icon-container img {
  width: 0.5rem;
}
.btn--read-more span {
  transform: translateY(2px);
  padding-right: 1rem;
  font-weight: 500;
  display: inline-block;
}
.btn--read-more-icon-only {
  padding: 0;
}
.btn--read-more-icon-only .icon-container {
  margin: 0;
}
.btn--read-more-icon-only span {
  display: none;
}
.btn--icon {
  padding-left: 1rem;
  padding-right: 1rem;
}

.menu {
  height: 5rem;
}
.menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}
.menu__list .menu__listitem {
  display: inline-block;
  height: 100%;
}
.menu__list .menu__listitem a {
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  display: inline-block;
  color: var(--bs-secondary);
  padding: 1.71rem 1.5rem;
  text-decoration: none;
}
.menu__list .menu__listitem > .menu__list {
  display: none;
}
.menu__list .menu__listitem--has-sub-items a:hover {
  color: red;
}
.menu__list .menu__listitem--has-sub-items a:hover > .menu__list {
  display: none;
}

.subtitle {
  padding-left: 5rem;
  margin-bottom: 1.5rem;
  display: inline-block;
  position: relative;
  color: #fff;
}
.subtitle::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 4.375rem;
  background: #fff;
}

.filtering-filter.disabled {
  display: block;
}

.wh-form__fieldline.hide,
.filtering-group.hide,
.filtering-item.filtered {
  display: none;
}

.ww-p-overview--withfilters {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.ww-p-overview--withfilters .c-filters__filtertitle {
  list-style-type: none;
}
.ww-p-overview--withfilters .c-filters__filterblock {
  margin-bottom: 1.5rem;
}

.ww-p-overview__items {
  display: grid;
  gap: 2rem 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
}

@media (min-width: 768px) {
  .ww-p-overview--withfilters {
    grid-template-columns: 250px 1fr;
  }
  .ww-p-overview__filters {
    border-right: 1px solid #000;
    margin-right: 1rem;
  }
}
.ww-p-overview__button-filter {
  width: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  zoom: 1;
  background: #f0f0f0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: var(--header-height);
}
body:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  z-index: 100;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
body > main {
  flex: 1;
}

html.headermenuopen body:after {
  opacity: 0.85;
}

#mainpageitems {
  display: flex;
  flex-direction: column;
  gap: var(--widget-margin-bottom);
}

.wh-rtd__tablewrap.table {
  overflow-x: auto;
}

.modal-backdrop {
  z-index: 600;
}

.offcanvas {
  z-index: 700;
}

.sitebackground {
  background: red;
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100% !important;
  z-index: -1;
  background: linear-gradient(-75deg, white 50%, #eaebed 50%);
}

.nav-link.selected {
  font-weight: bold;
  color: var(--bs-primary);
}

.nav-item .dropdown-item.selected {
  color: var(--bs-primary);
}

.imagehoverlink {
  display: block;
  overflow: hidden;
}
.imagehoverlink img {
  display: block;
  transition: all 0.3s;
}
@media (hover: hover) {
  .imagehoverlink:hover img {
    transform: scale(1.05);
  }
}

@media (hover: hover) {
  .imagehoverlink--bigger:hover img {
    transform: scale(1.15);
  }
}

html:not(.wh-wrdauth--isloggedin) .wh-wrdauth__logout {
  display: none;
}

.ww-cookiewall__buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.ww-cookiewall__link--save {
  white-space: nowrap;
}

.ww-cookiewall__container {
  padding: 8px 32px 24px !important;
}

.ww-cookiewall__cookie-items {
  width: 100% !important;
}

.ww-cookiewall__text {
  height: 50vh;
  overflow: auto;
  padding: 20px 0;
}

.ww-cookiewall__message > * {
  font-size: unset !important;
  font-size: 14px !important;
}
.ww-cookiewall__message p {
  font-size: 14px;
}
.ww-cookiewall__message p a {
  color: var(--bs-primary);
}
.ww-cookiewall__message > h2,
.ww-cookiewall__message > h3 {
  font-size: 20px !important;
}

#gdpr-cookie-types .ww-cookiewall__message {
  padding: 16px 0;
}

#gdpr-cookie-check-back {
  top: 8px;
  left: 8px;
}
#gdpr-cookie-check-back:before {
  background-color: var(--bs-primary);
}
#gdpr-cookie-check-back:after {
  border-color: var(--bs-primary);
}

html.dealerportal body #mainpageitems {
  flex: 1;
  display: flex;
  flex-direction: column;
}
html.dealerportal body #mainpageitems footer {
  margin-top: auto;
}
html.dealerportal .header {
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
}
html.dealerportal main {
  margin-top: 2rem;
}

.wh-anchor {
  scroll-margin-top: calc(var(--header-height) + 10px);
}

.wh-form__fields .wh-anchor {
  scroll-margin-top: calc(var(--header-height) + 45px);
}
/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-textimage__grid {
  display: grid;
}

.w-textimage__image {
  display: block;
  max-width: 100%;
  width: 100%;
  aspect-ratio: 4/3;
}

.w-textimage__content {
  position: relative;
  z-index: 2;
  padding: calc(1.325rem + 0.9vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1200px) {
  .w-textimage__content {
    padding: 2rem;
  }
}
.w-textimage__content--primary {
  background: var(--bs-primary);
}
.w-textimage__content--secondary {
  background: var(--bs-secondary);
}
.w-textimage__content--secondary .btn:hover {
  --bs-btn-hover-border-color: var(--bs-primary);
}
.w-textimage__content h2,
.w-textimage__content h3,
.w-textimage__content h4,
.w-textimage__content h5 {
  color: #fff;
}
.w-textimage__content p {
  color: #fff;
}

.w-textimage__image {
  position: relative;
}
.w-textimage__image img {
  width: 100%;
}
.w-textimage__image--left {
  margin-left: 0;
}

.w-textimage--fullwidth .w-textimage__content {
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}

@media (min-width: 576px) {
  .w-textimage__image {
    aspect-ratio: 16/9;
  }
}
@media (min-width: 768px) {
  .w-textimage--ingrid .w-textimage__content {
    width: calc(100% + 72px);
  }
  .w-textimage--ingrid.w-textimage--imageleft .w-textimage__content {
    transform: translateX(-72px);
  }
  .w-textimage__image {
    aspect-ratio: initial;
    height: 100%;
  }
  .w-textimage__grid {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }
  .w-textimage__content {
    --margin: 32px;
    margin-top: var(--margin);
    margin-bottom: var(--margin);
  }
}
@media (min-width: 992px) {
  .w-textimage__image {
    aspect-ratio: 16/9;
  }
  .w-textimage--fullwidth .w-textimage__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .w-textimage .w-textimage__contentinner {
    max-width: 611px;
  }
  .w-textimage--fullwidth.w-textimage--imageright .w-textimage__content {
    align-items: flex-end;
  }
}
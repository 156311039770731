/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-specs__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 3rem 2rem;
}
@media (min-width: 1200px) {
  .w-specs__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.w-specs__label {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  position: relative;
  padding: 0 0 12px;
  margin: 0 0 16px;
}
.w-specs__label::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 74px;
  height: 2px;
  background: var(--bs-primary);
}
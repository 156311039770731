/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-productcategories {
  display: grid;
  gap: 2rem;
}

.w-productcategories__products {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.w-productcategories__product {
  position: relative;
  display: block;
  text-decoration: none;
  overflow: hidden;
}
@media (hover: hover) {
  .w-productcategories__product:hover .w-productcategories__item {
    transform: scale(1.05);
  }
}
.w-productcategories__product::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

.w-productcategories__item {
  aspect-ratio: 1;
  position: relative;
  display: block;
  border-radius: 0.2rem;
  transition: all 0.3s;
}

.w-productcategories__title {
  position: absolute;
  z-index: 15;
  bottom: 0;
  width: 100%;
  padding: 1rem 4rem 1rem 1rem;
  padding: 0.5rem;
  color: #fff;
}

.w-productcategories__productlinkbutton {
  position: absolute;
  z-index: 15;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  border: 2px solid #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-productcategories__productlinkbutton img {
  display: block;
  transform: translateX(1px);
}

@media (min-width: 768px) {
  .w-productcategories {
    grid-template-columns: 300px 1fr;
  }
}
@media (min-width: 992px) {
  .w-productcategories__item {
    aspect-ratio: 4/3;
  }
}
@media (min-width: 1200px) {
  .w-productcategories__item {
    aspect-ratio: 16/9;
  }
}
@media (min-width: 1400px) {
  .w-productcategories__products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .w-productcategories__item {
    aspect-ratio: initial;
    height: 440px;
  }
  .w-productcategories__title {
    padding: 1rem;
  }
}
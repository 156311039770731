/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.griditem {
  display: block;
  background-color: #fff;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s;
  height: 100%;
  position: relative;
}

@media (hover: hover) {
  .griditem[href]:hover {
    transform: scale(1.05);
  }
}

.griditem__image {
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
}

.griditem__content {
  padding: 1rem;
}

.griditem__readmorecontainer {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
}

.griditem__summary--sublinks {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.griditem__subtitle {
  margin-top: -2px;
  font-weight: 700;
  margin-bottom: 8px;
}
/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.page-dealerapp main {
  margin-top: 0;
}
html.page-dealerapp .p-dealerapp__topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
html.page-dealerapp .c-categories {
  display: flex;
  gap: 1rem;
  margin-bottom: var(--widget-margin-bottom);
  flex-wrap: wrap;
}
html.page-dealerapp .dealerportalgrid .ww-p-overview__items {
  grid-template-columns: 1fr;
  gap: 0;
}
html.page-dealerapp .dealerportalgrid .griditem {
  background-color: transparent;
  border-bottom: 1px solid #000;
  padding: 1rem 0.5rem;
  transition: background-color 0.3s, color 0.3s;
}
html.page-dealerapp .dealerportalgrid .griditem .griditem__title.h3,
html.page-dealerapp .dealerportalgrid .griditem .griditem__subtitle {
  transition: color 0.3s;
}
@media (hover: hover) {
  html.page-dealerapp .dealerportalgrid .griditem:hover {
    background-color: var(--bs-primary);
  }
  html.page-dealerapp .dealerportalgrid .griditem:hover .griditem__title.h3,
  html.page-dealerapp .dealerportalgrid .griditem:hover .griditem__subtitle {
    color: #fff;
  }
}
html.page-dealerapp .dealerportalgrid .griditem.shadow {
  box-shadow: none !important;
}
@media (hover: hover) {
  html.page-dealerapp .dealerportalgrid .griditem:hover {
    transform: none;
  }
}
html.page-dealerapp .dealerportalgrid .griditem__content {
  padding: 0;
}
@media (min-width: 768px) {
  html.page-dealerapp .dealerportalgrid .griditem__title-summary {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 0 2rem;
    align-items: center;
  }
}
html.page-dealerapp .dealerportalgrid .griditem__title,
html.page-dealerapp .dealerportalgrid .griditem__subtitle {
  margin-bottom: 0;
  font-size: 18px;
}
@media (min-width: 768px) {
  html.page-dealerapp .dealerportalgrid .griditem__title,
  html.page-dealerapp .dealerportalgrid .griditem__subtitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
}
html.page-dealerapp .dealerportalgrid .griditem__subtitle {
  text-align: right;
  font-weight: 400;
  font-size: 16px;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  html.page-dealerapp .dealerportalgrid .griditem__subtitle {
    margin-top: 0;
  }
}
html.page-dealerapp .dealerportalgrid .griditem__readmorecontainer {
  display: none;
}
/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.site-footer {
  background: var(--bs-secondary);
  position: relative;
}
.site-footer a {
  color: #fff;
}
.site-footer p {
  color: #fff;
}
.site-footer h4 {
  color: #fff;
  margin-bottom: 1em;
}
.site-footer__bottom {
  border-top: 1px solid #fff;
  padding: 1rem 0;
  width: 100%;
}
.site-footer__bottom p {
  margin-bottom: 0;
}
.site-footer__menu {
  text-align: center;
}
.site-footer__grid {
  padding: 3rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
@media (min-width: 768px) {
  .site-footer__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 1rem;
  }
}
@media (min-width: 992px) {
  .site-footer__grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .site-footer__grid .site-footer__logo {
    display: none;
  }
}
@media (min-width: 1200px) {
  .site-footer__grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .site-footer__grid .site-footer__logo {
    display: block;
  }
}

.site-footer__bottomgrid {
  padding: 0.5rem 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: #fff;
}

.site-footer__menu li {
  margin-bottom: 0.2rem;
}

@media (min-width: 992px) {
  .site-footer__bottomgrid {
    flex-direction: row;
    justify-content: space-between;
  }
  .site-footer__menu ul {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
}
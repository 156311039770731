/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.p-products__categories {
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.p-products__image {
  width: 100%;
  height: 200px;
}

.p-categorypage__overview .ww-p-overview {
  margin-top: var(--widget-margin-bottom);
}

.p-categorypage__categories {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: var(--widget-margin-bottom);
}
@media (min-width: 576px) {
  .p-categorypage__categories {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .p-categorypage__categories {
    display: flex;
    flex-flow: row wrap;
  }
}

.p-categorypage__categorylink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-font-black);
  height: 50px;
  border-radius: 25px;
  border: 1px solid var(--color-font-black);
  text-decoration: none;
  padding: 0 30px;
  transition: color 0.3s, background 0.3s;
  line-height: 120%;
}
@media (hover: hover) {
  .p-categorypage__categorylink:hover {
    background: var(--bs-primary);
    color: #fff;
  }
}
.p-categorypage__categorylink.selected {
  background: var(--bs-primary);
  color: #fff;
}
/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-recent_occasions__grid {
  display: grid;
  grid-template-areas: "text" "products" "occasions";
  gap: 2rem;
}

.w-recent_occasions .c-items__items {
  display: grid;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}
.w-recent_occasions .c-items__item {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr min-content;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.w-recent_occasions .c-items__item:last-child {
  border-bottom: 0;
}
.w-recent_occasions .w-recent_occasions__text {
  grid-area: text;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.w-recent_occasions .w-recent_occasions__text .widget--button:last-child {
  margin-top: auto;
}
.w-recent_occasions .w-recent_occasions__col--products {
  grid-area: products;
}
.w-recent_occasions .w-recent_occasions__col--occasions {
  grid-area: occasions;
}
.w-recent_occasions .c-items__item-image {
  max-width: 100%;
  width: 100%;
  aspect-ratio: 16/9;
}
.w-recent_occasions .c-items__item-title,
.w-recent_occasions .c-items__item-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-recent_occasions .c-items__item-title {
  margin-bottom: 0;
  font-size: 18px;
}
@media (min-width: 576px) {
  .w-recent_occasions__grid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text text" "products occasions";
  }
  .w-recent_occasions__text {
    padding-bottom: 16px;
  }
}
@media (min-width: 1200px) {
  .w-recent_occasions__grid {
    grid-template-columns: 2fr 3fr 3fr;
    grid-template-areas: "text products occasions";
  }
}